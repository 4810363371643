'use client';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Suspense, useEffect, useState, useTransition } from 'react';
import slugify from 'react-slugify';

import { ReactComponent as CloseIcon } from '@/app/_icons/CkClose.svg';
import { ReactComponent as BurgerIcon } from '@/app/_icons/CkHamburger.svg';
import { ReactComponent as LocationIcon } from '@/app/_icons/CkLocationIcon16.svg';
import { ReactComponent as KiaLogo } from '@/app/_icons/kia-logo-white.svg';
import { ShoppingToolsMenu } from '@/services/menu/shoppingtools.service';
import { NewCar } from '@/types/new-cars/new-cars.types';

import './Hamburger.scss';
import { SubMenu } from './SubMenu';

export const Hamburger = ({
  newCars,
  selectedDealershipName,
  shoppingToolsItems,
  new_car_mega_menu_disclaimer,
}: {
  newCars: NewCar[];
  selectedDealershipName?: string;
  shoppingToolsItems: ShoppingToolsMenu | null;
  new_car_mega_menu_disclaimer?: string | null;
}) => {
  const [isOpen, setOpen] = useState(false);

  const onOpen = () => {
    startTransition(() => {
      setOpen(true);
    });
  };
  const onClose = () => {
    startTransition(() => {
      setOpen(false);
    });
  };

  const pathname = usePathname();

  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <>
      {!isOpen && !isPending ? (
        <button
          role="button"
          name="Hamburger menu"
          className="hamburgerIcon"
          onClick={onOpen}
        >
          <BurgerIcon />
        </button>
      ) : (
        <button
          role="button"
          name="Close menu"
          className="MenuCloseIcon"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      )}

      <Suspense fallback={null}>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          preserveScrollBarGap
          size="full"
          variant="clickThrough"
          id="mobileDrawer"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              className="mobileDrawerClose"
              color={'white'}
              top={6}
              right={5}
              role="button"
              name="closeHamburger"
              onClick={onClose}
            />
            <DrawerHeader
              border={0}
              paddingY={7}
              className="mobileDrawerHeader"
              position="sticky"
              top={0}
              zIndex={1}
              backgroundColor="var(--chakra-colors-black)"
            >
              {selectedDealershipName ? (
                <>
                  <Link
                    role="link"
                    className="logo"
                    href={`/dealerships/${slugify(selectedDealershipName)}`}
                  >
                    <KiaLogo
                      className="KiaLogo"
                      width={'80px'}
                      height={'20px'}
                    />
                    <Text fontSize="xs" color={'white'} marginTop={2}>
                      {selectedDealershipName}
                    </Text>
                  </Link>
                </>
              ) : (
                <Link role="link" className="logo" href="/">
                  <KiaLogo className="KiaLogo" width={'80px'} height={'20px'} />
                </Link>
              )}
            </DrawerHeader>
            <DrawerBody className="mobileDrawerBody" border={0}>
              <SubMenu
                closeHamburger={onClose}
                newCars={newCars}
                selectedDealershipName={selectedDealershipName}
                text="New Cars"
                new_car_mega_menu_disclaimer={new_car_mega_menu_disclaimer}
              />
              <SubMenu
                closeHamburger={onClose}
                text="Pre-Owned "
                url="/used-cars/search"
              />
              <SubMenu
                closeHamburger={onClose}
                text="Shopping Tools"
                shoppingToolsItems={shoppingToolsItems}
              />
              <SubMenu
                closeHamburger={onClose}
                text="Promotions"
                url="/promotions"
              />
              <SubMenu
                closeHamburger={onClose}
                text="Experience Kia"
                url="/blog"
              />
              <SubMenu closeHamburger={onClose} text="Financial Services" />
              {!selectedDealershipName ? (
                <SubMenu
                  closeHamburger={onClose}
                  text="Find a dealer"
                  icon={<LocationIcon />}
                  url="/find-a-dealer"
                />
              ) : null}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Suspense>
    </>
  );
};
