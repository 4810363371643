'use client';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import React, { useCallback, useState, useTransition } from 'react';

import { ReactComponent as CloseBlackIcon } from '@/app/_icons/CkClose_black.svg';
import { ReactComponent as SearchWhiteIcon } from '@/app/_icons/CkSearchWhite.svg';
import { BlogItem } from '@/types/blog/blog.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import './GlobalSearch.scss';

const InstantSearch = dynamic(() => import('./InstantSearch'));

interface Props {
  config: {
    search_quick_links: {
      title: string;
      url: string;
    }[];
  };
  newCars: NewCar[];
  latestBlogPosts: BlogItem[];
}

const GlobalSearch = ({ config, newCars, latestBlogPosts }: Props) => {
  const [hasSearched, setHasSearched] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isPending, startTransition] = useTransition();

  const handleSelectItem = () => {
    startTransition(() => {
      setIsDrawerOpen(false);
    });
  };

  const queryHook = useCallback(
    (query: string, searchFn: (query: string) => any) => {
      if (query && query.length) {
        searchFn(query);
        setHasSearched(true);
      } else {
        setHasSearched(false);
      }
    },
    [setHasSearched],
  );

  return (
    <>
      <Box className="headerGlobalSearchBtnDesk">
        <Button
          onClick={() => {
            startTransition(() => {
              setIsDrawerOpen(true);
              setHasSearched(false);
            });
          }}
          variant="solid"
          borderRadius={0}
          className="searchDrawerBtn"
        >
          <SearchWhiteIcon className="searchIcon" />
          Search
        </Button>
      </Box>
      <Box className="headerGlobalSearchBtnMob">
        <Button
          onClick={() => {
            startTransition(() => {
              setIsDrawerOpen(true);
              setHasSearched(false);
            });
          }}
          variant="solid"
          borderRadius={0}
          className="searchDrawerBtn"
        >
          {isPending ? '...' : <SearchWhiteIcon className="searchIcon" />}
        </Button>
      </Box>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => {
          startTransition(() => {
            setIsDrawerOpen(false);
          });
        }}
        preserveScrollBarGap
        size="md"
        id="globalSearchWrap"
      >
        <DrawerOverlay />
        <DrawerContent>
          {isDrawerOpen ? (
            <>
              <Button
                variant="solid"
                onClick={() => {
                  startTransition(() => {
                    setIsDrawerOpen(false);
                    setHasSearched(false);
                  });
                }}
                borderRadius={0}
                className="searchDrawerCloseBtn"
              >
                <CloseBlackIcon className="closeIcon" />
                <Text className="hideMobileClose">Close</Text>
              </Button>
              <DrawerBody
                paddingX={{ base: 6, sm: 10 }}
                paddingTop={{ base: 0 }}
                paddingBottom={{ base: 6, sm: 8 }}
              >
                <Box>
                  <InstantSearch
                    hasSearched={hasSearched}
                    onSelectItem={handleSelectItem}
                    queryHook={queryHook}
                    config={config}
                    newCars={newCars}
                    latestBlogPosts={latestBlogPosts}
                  />
                </Box>
              </DrawerBody>
            </>
          ) : null}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default GlobalSearch;
