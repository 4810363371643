import { isFuture } from 'date-fns';

import { assetsUri } from '@/services/api.service';
import { DirectusFile } from '@/types/files/files.types';
import { ChildFilter, ParentFilter } from '@/types/filters/filters.types';
import { RoutesConfig } from '@/types/navigation/navigation.types';
import { Vehicle, VehicleType } from '@/types/used-cars/used-cars.types';

import { SpecialDetails } from './SpecialsService.service';

export const getDownloadLink = (id: string) => `${assetsUri}/${id}?download`;

export const getModelsPagePath = (
  brand: ParentFilter,
  model?: ChildFilter,
  carType?: number | string,
) => {
  let mName = model && model.name ? model.name : '';

  if (model && model.name && model.name.indexOf('-') > -1) {
    mName = mName.replace(/-/g, '_').toLowerCase();
  }

  let carTypeString: string | number | undefined = carType;

  if (typeof carType === 'string') {
    carTypeString = carType === 'demo' ? carType : undefined;
  } else {
    carTypeString = carType === 3 ? 'demo' : undefined;
  }

  return `/used-cars${carTypeString ? `/${carTypeString}` : ''}/${brand.name.replace(/ /g, '-').toLowerCase()}${mName ? `/${mName.replace(/ /g, '-').toLowerCase()}` : ''}`;
};

export const getDealershipsPagePaths = (
  province: ParentFilter,
  dealership: ChildFilter,
  carType = '',
) =>
  `/used-cars${carType ? `/${carType}` : ''}/provinces/${province.name
    .toLowerCase()
    .split(' ')
    .join('-')}/dealerships/${dealership.name
    .toLowerCase()
    .split(' ')
    .join('-')}`;

/**
 *
 * @param image Directus file ID
 * @returns Directus full image url
 */

export const getImageLink = (image: string | null | undefined) => {
  if (image) {
    return `${assetsUri}/${image}`;
  }
  return null;
};

/**
 *
 * @param imageUrl full url generated by getImageLink
 * @returns Directus file ID
 */

export const getImageId = (imageUrl: string | null) =>
  imageUrl ? imageUrl.replace(`${assetsUri}/`, ``) : '';

/**
 * @param fileName full filename with extension
 * @returns filename without extension
 */

export const getFilenameWithoutExtension = (fileName: string) =>
  fileName.replace(/\.[^/.]+$/, '');

/**
 * @param imageIdOrUrl The ID or full url of the image
 * @param imageObjects Array of image objects to search from
 * @returns the title of an image if found in provided array of Directus image objects
 */

export const getImageTitle = (
  imageIdOrUrl: string,
  imageObjects: DirectusFile[],
) =>
  imageObjects.find(
    (imageObject) => imageObject.id === getImageId(imageIdOrUrl),
  )?.title;

export const removeWhiteSpace = (brand: string) => {
  return brand.replace(/ /g, '');
};

export const getSpecialSource = (special: SpecialDetails) => {
  const brand = special.range?.name ?? special.title;
  const type = special.vehicle_type;

  if (special?.source) {
    return special.source;
  }

  if (type === VehicleType.Used || type === VehicleType.Konfidence)
    return 'KR-PRICECRUSH';

  return brand
    ? removeWhiteSpace('KR-SPECIALSNEW-' + brand.toUpperCase())
    : 'KR-SPECIALSNEW';
};

export const setSourceCode = (
  vehicle: Vehicle | null,
  range: string | null,
  special?: SpecialDetails,
) => {
  if (vehicle && vehicle.sale_end_date) {
    const saleEnd = new Date(vehicle.sale_end_date);

    if (isFuture(saleEnd)) {
      return vehicle.sale_source;
    }
  }

  if (special) {
    const brand = range ?? special.title;
    const type = special?.vehicle_type;

    if (special?.source) {
      return special.source;
    }

    if (type === VehicleType.Used || type === VehicleType.Konfidence)
      return 'KR-PRICECRUSH';

    return brand
      ? removeWhiteSpace('KR-SPECIALSNEW-' + brand.toUpperCase())
      : 'KR-SPECIALSNEW';
  }

  const type = vehicle?.type;
  const brand = vehicle?.brand;
  const vehicleRange = range ?? vehicle?.range_id;

  if (type == VehicleType.Used || type == VehicleType.Konfidence) {
    return brand ? removeWhiteSpace('KK-USEDCARS-' + brand) : 'KK-USEDCARS';
  }

  if (type == VehicleType.Demo) {
    return vehicleRange
      ? removeWhiteSpace('KR-DEMO-' + vehicleRange)
      : 'KR-DEMO';
  }
};

export const convertToLowerCase = (val: string) => {
  return val ? val.toLowerCase() : val;
};

export const getFloorCodeForSpecials = (
  usedFloorCode: string,
  newFloorCode: string,
  type: string | undefined,
): string => {
  if (type == '1' || type == '2') return usedFloorCode;
  return newFloorCode;
};

export const getLeadTypeForSpecial = (
  usedCar: string,
  newCar: string,
  type: string | undefined,
): string => {
  if (type == '1' || type == '2') return usedCar;
  return newCar;
};

export function capitalizeFirstLetter(string: string) {
  if (string) {
    const str = removeHyphen(string);

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } else {
    return string;
  }
}

export const removeHyphen = (str: string) => {
  return str ? str.trim().replace(/-/g, ' ') : '';
};
export const removeUnderscore = (str: string) => {
  return str ? str.trim().replace(/_/g, ' ') : '';
};

export const getSearchResultPath = (
  activeCarType: VehicleType | null,
  routes: RoutesConfig,
) => {
  if (activeCarType !== VehicleType.Demo) {
    return routes.searchResults.path;
  } else {
    return routes.searchResultsDemo.path;
  }
};

export const getBrowseItemLink = (
  name: string | undefined,
  activeCarType: VehicleType | null,
  routes: RoutesConfig,
) => {
  switch (name) {
    case 'body':
      if (activeCarType !== VehicleType.Demo) {
        return routes.body.path;
      } else {
        return routes.bodyDemo.path;
      }
    case 'brand':
      if (activeCarType !== VehicleType.Demo) {
        return routes.brand.path;
      } else {
        return routes.brandDemo.path;
      }
    case 'location':
      if (activeCarType !== VehicleType.Demo) {
        return routes.location.path;
      } else {
        return routes.locationDemo.path;
      }
    case 'brands':
      if (activeCarType !== VehicleType.Demo) {
        return routes.brands.path;
      } else {
        return routes.brandsDemo.path;
      }
    case 'provinces':
      if (activeCarType !== VehicleType.Demo) {
        return routes.provinces.path;
      } else {
        return routes.provincesDemo.path;
      }
    case 'prices':
      if (activeCarType !== VehicleType.Demo) {
        return routes.prices.path;
      } else {
        return routes.pricesDemo.path;
      }
    default:
      if (activeCarType !== VehicleType.Demo) {
        return routes.types.path;
      } else {
        return routes.typesDemo.path;
      }
  }
};

export const getEmbededVideoLink = (videoLink?: string): string | null => {
  if (!videoLink) {
    return null;
  }

  let videoId = videoLink?.split('v=')[1];

  if (videoId) {
    const ampersandPosition = videoId?.indexOf('&');

    if (ampersandPosition !== -1) {
      videoId = videoId?.substring(0, ampersandPosition);
    }
  } else if (!videoId && videoLink?.match(/youtu\.be\//gi)) {
    const parsedVideoLink = videoLink?.split('/');

    videoId = parsedVideoLink[parsedVideoLink.length - 1];
  }
  return videoId || null;
};
