'use client';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import cn from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { Suspense, useEffect, useTransition } from 'react';

import { useFloatingCTA } from '@/hooks/floating-cta.hook';
import type { PopiaNoticeField } from '@/types/config/config.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import {
  EnquiryForms,
  LeadFormEnum,
  LeadFormType,
} from '../modals/enquire-forms/enquiry-forms/enquiry-forms';

import Style from './BannerFloatingCTA.module.scss';

const LABELS = {
  '/after-sales/book-a-service': 'Book a service',
  '/after-sales/parts-and-accessories': 'Enquire about parts',
  '/corporate-fleet-solutions/.*': 'Enquire Now',
  '/find-a-dealer': 'Find a dealer',
  '/new-cars/.*': 'Enquire Now',
  general: 'Enquire Now',
} as { [key: string]: string };

const DEFAULT_TYPES = {
  '/after-sales/book-a-service': 'service',
  '/after-sales/parts-and-accessories': 'parts',
  '/corporate-fleet-solutions/.*': 'fleet',
  '/new-cars/.*': 'new-cars',
  '/find-a-dealer': 'general',
} as { [key: string]: LeadFormType };

const MOBILE_HIDDEN = ['/build-price/.*'];

const ALWAYS_HIDE_CTA = [
  '/used-cars',
  '/used-cars/search',
  '/used-cars/demo/search',
  '/used-cars/demo',
];

const BUTTON_LINKS = {
  '/after-sales/book-a-service':
    process.env.NEXT_PUBLIC_BOOK_A_SERVICE_URL || null,
} as { [key: string]: string };

const USED_CAR_HIDE_PATTERN = /^\/used-cars\/(?!(.*\/\d+$)).+$/;

type Props = {
  btnText?: string;
  className?: string;
  extraClass?: string;
  moduleClass?: string;
  children?: React.ReactNode;
  newCars: NewCar[];
  dealerName?: string;
  documentPaiaPopia: PopiaNoticeField;
};

const BannerFloatingCTA = ({
  className = 'btnRed',
  extraClass = '',
  moduleClass = 'btnRed',
  children,
  newCars,
  dealerName,
  documentPaiaPopia,
  ...otherProps
}: Props) => {
  const {
    isCTAShown,
    isDrawerOpen,
    openLeadForm,
    closeLeadForm,
    leadType,
    secondaryFloatingButton,
    primaryButtonAction,
  } = useFloatingCTA();

  const pathname = usePathname();
  const params = useSearchParams();
  const form = params.get('form');

  useEffect(() => {
    if (form && Object.values(LeadFormEnum).includes(form as LeadFormEnum)) {
      openLeadForm(form as LeadFormEnum);
      // Remove from params:
      const url = new URL(window.location.href);

      url.searchParams.delete('form');
      window.history.replaceState({}, '', url);
    }
  }, [form]);

  const matchedPath = Object.keys(LABELS).find((key) =>
    new RegExp(key).test(pathname),
  );
  const buttonText = matchedPath ? LABELS[matchedPath] : LABELS['general'];
  const mobileHidden = MOBILE_HIDDEN.some((path) =>
    new RegExp(path).test(pathname),
  );
  const matchedLinkPath = Object.keys(BUTTON_LINKS).find((key) =>
    new RegExp(key).test(pathname),
  );
  const link = matchedLinkPath ? BUTTON_LINKS[matchedLinkPath] : '';

  const shouldShowCTA = () => {
    if (ALWAYS_HIDE_CTA.includes(pathname)) {
      return false;
    }

    if (USED_CAR_HIDE_PATTERN.test(pathname)) {
      return false;
    }

    return true;
  };

  const [isPending, startTransition] = useTransition();

  const getSecondaryButtonClassName = () => {
    const classes = [Style.btn, Style.secondaryFloatingBtn, Style.btnRed];

    if (secondaryFloatingButton) {
      switch (secondaryFloatingButton.display) {
        case 'desktop':
          classes.push(Style.desktopOnly);
          break;
        case 'mobile':
          classes.push(Style.mobileOnly);
          break;
      }
      if (secondaryFloatingButton.colors) {
        classes.push(Style.btn);
        classes.push(Style.customColors);
        if (secondaryFloatingButton.colors.desktop) {
          classes.push(Style.desktop);
        }
        if (secondaryFloatingButton.colors.mobile) {
          classes.push(Style.mobile);
        }
      }
    }

    return cn(...classes);
  };

  const getSecondaryButtonStyle = () => {
    if (!secondaryFloatingButton?.colors) return {};

    const style: React.CSSProperties = {
      '--desktop-bg': secondaryFloatingButton.colors.desktop?.background,
      '--desktop-text': secondaryFloatingButton.colors.desktop?.text,
      '--desktop-hover-bg':
        secondaryFloatingButton.colors.desktop?.hoverBackground,
      '--desktop-hover-text': secondaryFloatingButton.colors.desktop?.hoverText,
      '--mobile-bg': secondaryFloatingButton.colors.mobile?.background,
      '--mobile-text': secondaryFloatingButton.colors.mobile?.text,
      '--mobile-hover-bg':
        secondaryFloatingButton.colors.mobile?.hoverBackground,
      '--mobile-hover-text': secondaryFloatingButton.colors.mobile?.hoverText,
    } as any;

    return style;
  };

  const handlePrimaryButtonClick = () => {
    if (primaryButtonAction) {
      primaryButtonAction.onClick();
      if (primaryButtonAction.openForm !== false) {
        startTransition(() => {
          openLeadForm(
            matchedPath ? DEFAULT_TYPES[matchedPath] || 'general' : 'general',
          );
        });
      }
    } else {
      startTransition(() => {
        openLeadForm(
          matchedPath ? DEFAULT_TYPES[matchedPath] || 'general' : 'general',
        );
      });
    }
  };

  return (
    <>
      {isCTAShown && shouldShowCTA() && (
        <div
          className={cn(
            Style.floatingCtaBtn,
            {
              [Style.hideCta as string]: !isCTAShown,
              [Style.mobileHidden as string]: mobileHidden,
              [Style.hasSecondaryButton as string]: secondaryFloatingButton,
            },
            moduleClass,
          )}
          key="cta"
          {...otherProps}
        >
          {matchedLinkPath && link ? (
            <Link
              className={cn(
                Style.btn,
                {
                  [Style[className] as string]: className,
                  [Style[extraClass] as string]: extraClass,
                },
                moduleClass,
              )}
              href={link}
            >
              <span>
                {isPending ? 'Opening...' : children ? children : buttonText}
              </span>
            </Link>
          ) : (
            <Box className={Style.buttonContainer}>
              {secondaryFloatingButton &&
                (secondaryFloatingButton.url ? (
                  <Link
                    key={pathname}
                    href={secondaryFloatingButton.url}
                    className={getSecondaryButtonClassName()}
                    style={getSecondaryButtonStyle()}
                    {...otherProps}
                  >
                    <span>{secondaryFloatingButton.text}</span>
                  </Link>
                ) : (
                  <Button
                    className={getSecondaryButtonClassName()}
                    style={getSecondaryButtonStyle()}
                    onClick={secondaryFloatingButton.onClick}
                    {...otherProps}
                  >
                    <span>{secondaryFloatingButton.text}</span>
                  </Button>
                ))}
              <Button
                className={cn(
                  Style.btn,
                  Style.primaryBtn,
                  {
                    [Style[className] as string]: className,
                    [Style[extraClass] as string]: extraClass,
                  },
                  moduleClass,
                )}
                onClick={handlePrimaryButtonClick}
                {...otherProps}
              >
                <span>{isPending ? 'Opening...' : children || buttonText}</span>
              </Button>
            </Box>
          )}
        </div>
      )}
      <Suspense fallback={null}>
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={() => {
            startTransition(() => {
              closeLeadForm();
            });
          }}
        >
          <DrawerOverlay />
          <DrawerContent maxWidth={{ base: '100%', md: '460px' }}>
            {isPending ? (
              <Box
                display="flex"
                justifyContent="flex-end"
                position="absolute"
                right={6}
                top={2}
              >
                <Box>...</Box>
              </Box>
            ) : (
              <DrawerCloseButton />
            )}
            <DrawerBody padding={10}>
              <EnquiryForms
                type={isDrawerOpen ? leadType : null}
                onClose={() => {
                  startTransition(() => {
                    closeLeadForm();
                  });
                }}
                newCars={newCars}
                dealerName={dealerName}
                documentPaiaPopia={documentPaiaPopia}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Suspense>
    </>
  );
};

export default BannerFloatingCTA;
